.secdot {
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 5px;
}

.selected {
  background-color: grey;
}

.god2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
