body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 5px;
  width: 97vw;
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: antiquewhite;
}
.logo {
  width: 143px;
  height: 41px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.hamburguer-menu {
  width: 45px;
}
.fav {
  width: 45px;
}
.fav-text {
  margin-left: 2px;
  font-size: 30px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #fff;
}
#fav-text-black {
  color: black;
  color: #fff;
}
@media only screen and (min-width: 600px) {
  .header {
    top: 7%;
  }
  #fav-text-black {
    color: #fff;
  }
}

.intro {
  position: relative;
  height: 370px;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/environmental-protection-3341942_1920.9477157b.jpg);
  background-position: center top;
  background-size: 150vw 100vw;
}
.intro-bombi {
  width: 100vw;
}
.intro-header {
  position: absolute;
}
.intro-free {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #000000;
  color: white;
  font-size: 16px;
  padding: 12px 10px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  width: 55vw;
  box-shadow: 0.3rem 0.3rem 0.3rem #000000;
  font-size: 20px;
}

.who {
  background-color: #cdb787;
  padding: 27px;
}
.who-title {
  font-size: 24px;
  margin-bottom: 30px;
}
#who-title-bold {
  font-weight: bold;
}
.who-line {
  width: 100vw;
  margin-bottom: 40px;
}
.asses {
  height: 300px;
}
.asses-free-icon {
  width: 25px;
  height: 35px;
}
.asses-free {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 65vw;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  height: 60px;
}
.asses-free-data {
  width: 165px;
  color: black;
  text-decoration: none;
}
.asses-block {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 185px;
}
.asses-free-icon-mail {
  width: 35px;
  height: 30px;
}
#who-intro {
  color: #000;
  background-color: #cdb787;
}
#who-intro-desk {
  display: none;
}
.footer {
  background-color: black;
  color: white;
  background-color: black;
  color: white;
  font-size: 15px;
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: space-around;
          align-items: space-around;
  width: 100vw;
}
.footer-text-intro {
  margin-right: 10px;
}
@media only screen and (min-width: 600px) {
  .intro {
    height: 100vh;
  }
  .intro-free {
    display: none;
  }
  #who-intro-desk {
    display: -webkit-flex;
    display: flex;
    padding-top: 20%;
    width: 40%;
    text-align: left;
    font-size: 20px;
    margin-left: 30px;
  }
  .who-title-desk {
    margin-bottom: 30px;
    font-size: 23px;
  }
  .asses-free {
    width: 40vw;
  }
  .group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100vw;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 44vh;
    -webkit-align-items: center;
            align-items: center;
  }
  .asses {
    -webkit-align-items: center;
            align-items: center;
  }
  #who-intro {
    display: none;
  }
  .who-line {
    display: none;
  }
  .footer-text-intro {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100vw;
  }
  .footer {
    height: 50px;
  }
}

.mapa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 50px;
}
.markercluster-map {
  height: 250px;
  width: 80vw;
}
@media only screen and (min-width: 600px) {
  .markercluster-map {
    height: 250px;
    width: 40vw;
  }
}

.form {
  background-color: #cdb787;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.form-title {
  margin-top: 20px;
  padding-top: 20px;
  font-size: 24px;
  margin-bottom: 15px;
}
.app-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  width: 81vw;
  min-height: 90vh;
}
.form-button {
  border: none;
  border-radius: 0.4em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  color: #fbfbfb;
  width: 30vw;
  height: 45px;
  font-size: 17px;
}
.form-button:hover {
  color: #000000;
  background-color: #ffffff;
}
.form-element {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
input {
  width: 80vw;
  margin-bottom: 10px;
  height: 45px;
  border: none;
  border-radius: 3px;
  font-size: large;
  padding-left: 20px;
}
textarea {
  border-radius: 3px;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 18px;
}
textarea:focus {
  border: solid 1px #39ff14;
}
input:focus {
  outline: none;
  border: solid 1px #39ff14;
}
#message {
  height: 120px;
  width: 80vw;
  padding: 10px;
}
.form-factura {
  height: 60px;
}
.form-factura-img {
  height: 60px;
  width: 55px;
}
.form-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px 0px;
}
.form-factura-text {
  width: 20vw;
  font-size: 15px;
}
@media only screen and (min-width: 600px) {
  .form {
    background-color: rgba(205, 183, 135, 0.7);
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: 124px;
    height: 80vh;
    width: 46vw;
    right: 2vw;
  }
  .app-form {
    width: 37vw;
  }
  input {
    width: 36vw;
  }
  #message {
    width: 36vw;
    margin-bottom: 65px;
  }
  .form-button {
    width: 15vw;
  }
  textarea:focus {
    border: solid 1px #39ff14;
  }
  .app-form {
    min-height: 0vh;
  }
}

.foundsome {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.foundsome-header {
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  width: 75%;
  color: #000035;
}
.foundsome-article {
  width: 75%;
  width: 88%;
  height: 479px;
  box-shadow: -3px -3px 20px 0 rgba(220, 210, 216, 0.5),
    3px 3px 20px 0 rgba(220, 210, 216, 0.5);
  background-color: #fbfbfb;
  border-radius: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.foundsome-pcontainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.foundsome-p {
  width: 75%;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  display: -webkit-flex;
  display: flex;
  text-align: left;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 0.18px;
  color: #000035;
}

.foundsome-image {
  width: 225.47px;
  margin-bottom: 15px;
  height: 171.07px;
}
.foundsome-button {
  border-radius: 10px;
  background: #224bdd;
  box-shadow: 2px 2px 4px #847a80, inset 4px 4px 4px #3f65ea;
  border: none;
  height: 30px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #fbfbfb;
  text-decoration: none;
  width: 55vw;
  height: 34px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 12px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
}
a.foundsome-goback {
  text-decoration: none;
  color: #224bdd;
  margin-bottom: 25px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
.error-message {
  font-size: calc(0.5em + 1vw);
  color: crimson;
}
@media only screen and (min-width: 600px) {
  .foundsome {
    width: 100vw;
    height: 100vh;
    position: absolute;
    width: 100vw;
    right: -8vw;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }
}

.image-uploader {
  /* padding-left: 15px; */
  width: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
.image-uploader-child {
  padding-left: 22px;
  padding-top: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 52.8px;
}
.add-photo-text {
  width: 134px;
  height: 30px;
  margin: 24px 25px 0 36.5px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #224bdd;
}
.add-photo-image {
  width: 78px;
  height: 78px;
  /* margin-left: 10px; */
}
.exit-icon {
  width: 12px;
  height: 12px;
  position: relative;
  top: 5px;
  background-color: #f5f5f5;
  right: 17px;
  border-radius: 50%;
}
.image-wrap {
  width: 78px;
  height: 78px;
  padding: 3px;
  display: -webkit-flex;
  display: flex;
}
.images-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
.plus-icon {
  width: 13.7px;
  height: 25.7px;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #224bdd;
}
.initial {
  width: 258px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.image-wrap-none {
  display: none;
}
#second-image {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
}
#image-number {
  width: 33px;
  height: 25px;
  margin: 14px 0 0;
  font-family: Mulish;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #f5f5f5;
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.form-factura-names {
}

.who-father {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/wcpr.7876f7bd.jpg);
  background-color: transparent;
}
.ham {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding-top: 2px;
  width: 95vw;
  padding: 15px 0px 15px 9px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.who {
  margin-bottom: -2px;
  background-color: transparent;
  color: #fff;
}
.who-text {
  font-size: 19px;
  text-align: left;
  margin-bottom: 25px;
}
@media only screen and (min-width: 600px) {
  .who-text {
    width: 60vw;
  }
  .who {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('./wcpr.jpg'); */
    background-color: transparent;
    color: #fff;
  }
  .who-father {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url(/static/media/wcpr.7876f7bd.jpg);
    background-color: transparent;
    height: 100vh;
  }
  .who-text-who {
    width: 40vw;
  }
  #tio {
    display: none;
  }
}

.Footer {
  background-color: black;
  color: white;
  background-color: black;
  color: white;
  font-size: 15px;
  text-align: right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: space-around;
          align-items: space-around;
  position: absolute;
  bottom: -17;
  width: 100vw;
  overflow: hidden;
}
.footer-text {
  margin-right: 10px;
}

@media only screen and (min-width: 600px) {
  .footer-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    position: absolute;
    width: 100vw;
    height: 50px;
  }
}

.asses {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: -15px;
  color: white;
}
#white {
  color: white;
}
@media only screen and (min-width: 600px) {
}

.hands {
  width: 100vw;
  margin-bottom: -5px;
}
.hamburguer-menu-black {
  width: 40px;
}
.hamburguer-menu-white {
  display: none;
}
.contact {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(/static/media/contact.a6aeef82.jpg);
}
@media only screen and (min-width: 600px) {
  .hands {
    display: none;
  }
  .asses-free {
    width: 40vw;
  }
  .group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100vw;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    height: 44vh;
    -webkit-align-items: center;
            align-items: center;
  }
  .asses {
    -webkit-align-items: center;
            align-items: center;
  }
  #group {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    width: 50vw;
    height: 95vh;
    -webkit-justify-content: center;
            justify-content: center;
    display: none;
  }
  .contact {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url(/static/media/contact.a6aeef82.jpg);
    height: 100vh;
  }
  .hamburguer-menu-black {
    display: none;
  }
  .hamburguer-menu-white {
    display: inline-block;
    width: 40px;
  }
  #form {
    right: 23vw;
  }
  #footer {
    position: absolute;
    bottom: 0;
  }
}

.icons {
  display: -webkit-flex;
  display: flex;
  height: 140vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  margin: 35px 0px 35px 0px;
}
.bubble {
  background-color: #cdb787;
  border-radius: 100%;
  width: 50vw;
  height: 50vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.bubble-icon {
  width: 40vw;
}
.bubble-title {
  font-size: 22px;
  margin-bottom: 80px;
}
.bubble:active {
  background-color: #39ff14;
}
.bubble:hover {
  background-color: #39ff14;
}
.bubble-father {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
@media only screen and (min-width: 600px) {
  .icons {
    height: 50vh;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .bubble {
    width: 20vw;
    height: 20vw;
  }
  .bubble-icon {
    width: 15vw;
  }
}

.black-line {
  height: 70px;
  width: 100vw;
}
.opinions-carrousel {
  height: 50vh;
  width: 100vw;
  background-color: #000;
  margin-top: -20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  font-size: 13px;
  padding-bottom: 50px;
}
.arrow {
  height: 30px;
  margin: 10px;
}
.empresa {
  width: 30%;
  height: 30%;
}
.opinion {
  display: -webkit-flex;
  display: flex;
  width: 80vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.opinion-text {
  text-align: left;
  width: 60%;
}
.opinion-text p:nth-child(3) {
  font-weight: bolder;
  font-style: italic;
}
@media only screen and (min-width: 600px) {
  .arrow {
    height: 55px;
  }
  .opinions-carrousel {
    font-size: 20px;
  }
}

.secdot {
  width: 300px;
  height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 5px;
}

.selected {
  background-color: grey;
}

.god2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.menu {
  background-color: black;
  height: 100vh;
}
.exit {
  width: 27px;
  margin-left: 82vw;
  margin-top: 3vh;
}
.menu-title {
  color: #fbfbfb;
  font-size: 30px;
}
.menu-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  text-align: left;
  margin-left: 40px;
}
a {
  text-decoration: none;
}
@media only screen and (min-width: 600px) {
  .menu-text {
    text-align: center;
    margin-top: 9vh;
  }
}

.Opinions2 {
  background-color: black;
  color: white;
  padding-top: 23px;
  padding-bottom: 75px;
}
#opinion-opinions {
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-size: 13px;
  border-bottom: 1px solid white;
  min-height: 300px;
  padding: 30px 0px 30px 0px;
}
.Opinions2 > h2 {
  margin-top: 96px;
}
@media only screen and (min-width: 600px) {
  #opinion-opinions {
    font-size: 20px;
  }
}

