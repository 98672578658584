.icons {
  display: flex;
  height: 140vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 35px 0px 35px 0px;
}
.bubble {
  background-color: #cdb787;
  border-radius: 100%;
  width: 50vw;
  height: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bubble-icon {
  width: 40vw;
}
.bubble-title {
  font-size: 22px;
  margin-bottom: 80px;
}
.bubble:active {
  background-color: #39ff14;
}
.bubble:hover {
  background-color: #39ff14;
}
.bubble-father {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 600px) {
  .icons {
    height: 50vh;
    flex-direction: row;
  }
  .bubble {
    width: 20vw;
    height: 20vw;
  }
  .bubble-icon {
    width: 15vw;
  }
}
