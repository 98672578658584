.Opinions2 {
  background-color: black;
  color: white;
  padding-top: 23px;
  padding-bottom: 75px;
}
#opinion-opinions {
  display: flex;
  width: 100vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
  border-bottom: 1px solid white;
  min-height: 300px;
  padding: 30px 0px 30px 0px;
}
.Opinions2 > h2 {
  margin-top: 96px;
}
@media only screen and (min-width: 600px) {
  #opinion-opinions {
    font-size: 20px;
  }
}
