.image-uploader {
  /* padding-left: 15px; */
  width: 150px;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}
.image-uploader-child {
  padding-left: 22px;
  padding-top: 14px;
  display: flex;
  flex-direction: row;
  height: 52.8px;
}
.add-photo-text {
  width: 134px;
  height: 30px;
  margin: 24px 25px 0 36.5px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: left;
  color: #224bdd;
}
.add-photo-image {
  width: 78px;
  height: 78px;
  /* margin-left: 10px; */
}
.exit-icon {
  width: 12px;
  height: 12px;
  position: relative;
  top: 5px;
  background-color: #f5f5f5;
  right: 17px;
  border-radius: 50%;
}
.image-wrap {
  width: 78px;
  height: 78px;
  padding: 3px;
  display: flex;
}
.images-wrap {
  display: flex;
  flex-flow: row wrap;
}
.plus-icon {
  width: 13.7px;
  height: 25.7px;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #224bdd;
}
.initial {
  width: 258px;
  display: flex;
  flex-direction: row;
}
.image-wrap-none {
  display: none;
}
#second-image {
  filter: brightness(0.3);
}
#image-number {
  width: 33px;
  height: 25px;
  margin: 14px 0 0;
  font-family: Mulish;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #f5f5f5;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-factura-names {
}
