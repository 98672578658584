.mapa {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
}
.markercluster-map {
  height: 250px;
  width: 80vw;
}
@media only screen and (min-width: 600px) {
  .markercluster-map {
    height: 250px;
    width: 40vw;
  }
}
