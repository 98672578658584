.foundsome {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.foundsome-header {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  width: 75%;
  color: #000035;
}
.foundsome-article {
  width: 75%;
  width: 88%;
  height: 479px;
  box-shadow: -3px -3px 20px 0 rgba(220, 210, 216, 0.5),
    3px 3px 20px 0 rgba(220, 210, 216, 0.5);
  background-color: #fbfbfb;
  border-radius: 30px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
}

.foundsome-pcontainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.foundsome-p {
  width: 75%;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  text-align: left;
  align-items: center;
  letter-spacing: 0.18px;
  color: #000035;
}

.foundsome-image {
  width: 225.47px;
  margin-bottom: 15px;
  height: 171.07px;
}
.foundsome-button {
  border-radius: 10px;
  background: #224bdd;
  box-shadow: 2px 2px 4px #847a80, inset 4px 4px 4px #3f65ea;
  border: none;
  height: 30px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #fbfbfb;
  text-decoration: none;
  width: 55vw;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
}
a.foundsome-goback {
  text-decoration: none;
  color: #224bdd;
  margin-bottom: 25px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
.error-message {
  font-size: calc(0.5em + 1vw);
  color: crimson;
}
@media only screen and (min-width: 600px) {
  .foundsome {
    width: 100vw;
    height: 100vh;
    position: absolute;
    width: 100vw;
    right: -8vw;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 14;
  }
}
