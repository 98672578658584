.menu {
  background-color: black;
  height: 100vh;
}
.exit {
  width: 27px;
  margin-left: 82vw;
  margin-top: 3vh;
}
.menu-title {
  color: #fbfbfb;
  font-size: 30px;
}
.menu-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  margin-left: 40px;
}
a {
  text-decoration: none;
}
@media only screen and (min-width: 600px) {
  .menu-text {
    text-align: center;
    margin-top: 9vh;
  }
}
