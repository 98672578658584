.intro {
  position: relative;
  height: 370px;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('./img/environmental-protection-3341942_1920.jpg');
  background-position: center top;
  background-size: 150vw 100vw;
}
.intro-bombi {
  width: 100vw;
}
.intro-header {
  position: absolute;
}
.intro-free {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #000000;
  color: white;
  font-size: 16px;
  padding: 12px 10px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  width: 55vw;
  box-shadow: 0.3rem 0.3rem 0.3rem #000000;
  font-size: 20px;
}

.who {
  background-color: #cdb787;
  padding: 27px;
}
.who-title {
  font-size: 24px;
  margin-bottom: 30px;
}
#who-title-bold {
  font-weight: bold;
}
.who-line {
  width: 100vw;
  margin-bottom: 40px;
}
.asses {
  height: 300px;
}
.asses-free-icon {
  width: 25px;
  height: 35px;
}
.asses-free {
  display: flex;
  flex-direction: row;
  width: 65vw;
  justify-content: space-evenly;
  align-items: center;
  height: 60px;
}
.asses-free-data {
  width: 165px;
  color: black;
  text-decoration: none;
}
.asses-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 185px;
}
.asses-free-icon-mail {
  width: 35px;
  height: 30px;
}
#who-intro {
  color: #000;
  background-color: #cdb787;
}
#who-intro-desk {
  display: none;
}
.footer {
  background-color: black;
  color: white;
  background-color: black;
  color: white;
  font-size: 15px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  width: 100vw;
}
.footer-text-intro {
  margin-right: 10px;
}
@media only screen and (min-width: 600px) {
  .intro {
    height: 100vh;
  }
  .intro-free {
    display: none;
  }
  #who-intro-desk {
    display: flex;
    padding-top: 20%;
    width: 40%;
    text-align: left;
    font-size: 20px;
    margin-left: 30px;
  }
  .who-title-desk {
    margin-bottom: 30px;
    font-size: 23px;
  }
  .asses-free {
    width: 40vw;
  }
  .group {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: space-around;
    height: 44vh;
    align-items: center;
  }
  .asses {
    align-items: center;
  }
  #who-intro {
    display: none;
  }
  .who-line {
    display: none;
  }
  .footer-text-intro {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
  }
  .footer {
    height: 50px;
  }
}
